import {
  useParams, useSearchParams
} from "react-router-dom";
import Posts from '../components/posts/Posts';

function NewsDetail() {
  let { slug } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('t');
  return (
    <Posts newsDetail={true} slug={slug} token={token} />
  );
}

export default NewsDetail;
