import { useState } from 'react'
import LeftCategory from '../LeftCategory';
import OpenMenu from '../../assets/mobile-icon.png';
import CloseMenu from '../../assets/close-icon.png';

export default function Navbar() {
  const [navbarOpen, setNavbarOpen] = useState(false);
  return (
    <div className='mobileBar'>
      <div className='toggle' onClick={() => setNavbarOpen((prev) => !prev)}>
        {navbarOpen ?
          <div className='closeBtn'><img alt='' src={CloseMenu} /></div>
          : <div className='openBtn'><img alt='' src={OpenMenu} /></div>
        }
      </div>
      <div className={`menuBar menu-nav${navbarOpen ? 'show-menu' : ''}`} >
        <LeftCategory handleMenuToggle={setNavbarOpen}/>
      </div>
    </div>
  )
}
