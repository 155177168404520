export default function PostSkeleton() {

  return <div className='post'>
    <div className='topbarPost'>
      <div className='postLogo'></div>
    </div>
    <div className='postContent'>
      <div className='skeleton-loader'></div>
      <div className='skeleton-loader'></div>
      <br />
      <div className="skeleton-loader" style={{ height: '250px' }}></div>
      <br />
      {/* <span className='shareText'>3hr • 21 shares</span> */}
      {/* <div className='space-between-container'>
        <div className='shareText '>{post?.categoryName}</div>
        <div className='shareText'>{formatDate(post?.publicationDate)}</div>
      </div> */}
      {/* <span className='shareText'>{post?.categoryName}</span> */}
      <div className='skeleton-loader'></div>
      <div className='skeleton-loader'></div>
      <div className='skeleton-loader'></div>
      <div className='skeleton-loader'></div>
      {/* <p dangerouslySetInnerHTML={{ __html: parseDescription(post) }} /> */}
    </div>
  </div>
}
