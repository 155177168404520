import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import Logo from "../../assets/logo.png";
// import SearchIcon from "../../assets/search-icon.png";
// import NotificationIcon from "../../assets/notification.png";
import Navbar from "./Navbar";
// import CategoryTabs from "./CategoryTabs";
import CategoryButton from "./CategoryButton";

export default function Header() {

  return (
    <div className="header">
      <div className="logo">
        <Box>
          <Link to="/news/me">
            <img alt="" src={Logo} />
          </Link>
        </Box>
      </div>
      <div className="tabs">
        {/* <CategoryTabs /> */}
        <Navbar />
        <CategoryButton />
      </div>
      {/* <div className="topRightbar">
        <div className="searchBar">
          <img alt="" src={SearchIcon} />
        </div>
        <div className="notification">
          <img alt="" src={NotificationIcon} />
        </div>
      </div> */}
    </div>
  );
}
