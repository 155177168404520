import {
  useParams, useSearchParams
} from "react-router-dom";
import Posts from '../components/posts/Posts';

function VideoDetail() {
  let { slug } = useParams();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('t');
  return (
    <Posts category="videos" newsDetail={true} slug={slug} token={token} />
  );
}

export default VideoDetail;
