import { Box, Grid } from "@mui/material";
import FooterLogo from "../../assets/logo.png";
// import FacebookPost from "../../assets/facebook-post.jpg";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="footer">
      <Grid container spacing={6}>
        <Grid item xs={12} md={10}>
          <Box>
            <img alt='' src={FooterLogo} />
            <p className="footerText">
              Unifying news channels, delivering diverse stories in one place. Stay informed like never before with Bhai goes digital.
            </p>
          </Box>
        </Grid>
        <Grid className="footerSections" item xs={12} md={2}>
          {/* <div className="companySection"> */}
          {/* <p>Company</p>
            <ul>
              <li>
                <Link to="/">A12bouts us</Link>
              </li>
              <li>
                <Link to="/">Press Release</Link>
              </li>
              <li>
                <Link to="/">Career</Link>
              </li>
              <li>
                <Link to="/">Contact Us</Link>
              </li>
            </ul> */}
          {/* </div> */}
          <div className="companySection">
            <p>Categories</p>
            <ul>
              <li>
                <Link to="/">News</Link>
              </li>
              {/* <li>
                <Link to="/">Virals</Link>
              </li> */}
              <li>
                <Link to="videos">Videos</Link>
              </li>
            </ul>
          </div>
        </Grid>
        {/* <Grid item xs={12} md={4}>
          <p>LIKE US ON FACEBOOK</p>
          <img alt='' className="fbPost" src={FacebookPost} />
        </Grid> */}
      </Grid>
    </div>
  );
}
