import axios from "axios";
import { routes } from "../constants/constant.routes";
const { API_URL } = routes;

const get = (endpoint, data) => {
  return apiRequest('get', endpoint, null, data);
}

const post = (endpoint, data, token) => {
  return apiRequest('post', endpoint, data, null, token);
}

const apiRequest = async (method, endpoint, body, queryParamObj, token) => {
  const axiosConfig = {
    baseURL: API_URL,
    method,
    url: endpoint
  };

  if (method === 'post' && body) {
    axiosConfig.data = body;
  }
  if (queryParamObj) {
    axiosConfig.params = queryParamObj;
  }

  if (token) {
    axiosConfig.headers = {
      authorization: `Bearer ${token}`
    }
  }
  try {
    const response = await axios(axiosConfig);
    return response.data;
  } catch (e) {
    throw e.response.error;
  }
}

export { get, post }