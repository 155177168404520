import {
  Route, Routes
} from 'react-router-dom';
import { Grid } from '@mui/material';

import { publicRouteObj } from './router-config';
import Header from '../components/header/Header';
import LeftCategory from '../components/LeftCategory';
import Sidebar from '../components/Sidebar';
import Footer from '../components/footer/Footer';

function CustomRoutes() {
  const publicRoutesObj = publicRouteObj.map((route) => {
    const Component = route.component;
    return (
      <Route
        key={route.key}
        exact={route.exact}
        path={route.path}
        element={(
          <div>
            <Header />
            <div className='contentPart'>
              <Grid container spacing={0}>
                <div className='leftBg mainLeftPart'>
                  <LeftCategory />
                </div>
                <div className='mainCenterPart'>
                  <Component />
                </div>
                <div className='mainRightPart'>
                  <Sidebar />
                </div>
              </Grid>
            </div>
            <Footer />
          </div>
        )}
      />
    );
  });


  return (
    <Routes>
      {publicRoutesObj}
      {/* if user enter wrong path redirect to home page */}
      {/* <Route path="*" element={<Dashboard />} /> */}
    </Routes>
  );
}

export default CustomRoutes;
