export default function SidebarPostSkeleton() {

  return <div className='postPopular'>
    <div className={`skeleton-loader  padding-0-imp`}></div>
    <div className='popularPosts' >
      <div className='postContent'>
        <div className="skeleton-loader"></div>
        <div className='space-between-container'>
          <div className='skeleton-loader'></div>
          <div className='skeleton-loader'></div>
        </div>
      </div>
    </div>
    <div className='popularPosts' >
      <div className='postContent'>
        <div className="skeleton-loader"></div>
        <div className='space-between-container'>
          <div className='skeleton-loader'></div>
          <div className='skeleton-loader'></div>
        </div>
      </div>
    </div>
  </div>
}
