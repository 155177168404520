import moment from 'moment';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const formatDate = (dateString) => {
  if (!dateString) {
    return '';
  }
  const dateFormat = 'DD MMM, YYYY'
  const momentDate = moment(dateString);
  const diff = moment().diff(momentDate, 'days');
  if(diff === 0){
    return 'Today'
  } else if(diff === 1){
    return 'Yesterday'
  }
  return moment(dateString).format(dateFormat);
}


const showToast = (msg, type) => {
  if (type === 'error') {
    toastr.error(msg);
  } else if (type === 'warning') {
    toastr.warning(msg);
  } else if (type === 'info') {
    toastr.info(msg);
  } else {
    toastr.success(msg);
  }
}

export { formatDate, showToast }