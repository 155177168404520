import CustomRoutes from './router/routes';
import './App.css';

function App() {
  return (
    <div>
      <CustomRoutes />
    </div>
  );
}

export default App;
