import {
  useParams
} from "react-router-dom";
import Posts from '../components/posts/Posts';

function Home() {
  let { category } = useParams();
  console.log("category...",category);
  return (
    <Posts category={category}/>
  );
}

export default Home;
