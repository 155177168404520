import { useParams } from 'react-router-dom'
import SidebarPost from './posts/SidebarPost';

export default function Sidebar() {
  const { category } = useParams();
  return (
    <>
      {/* Popular News component */}
      {category !== 'popular' && <SidebarPost category='popular' title="Popular News" />}
      {/* <ViralVideos /> */}
      {/* Latest News component */}
      {category === 'popular' && <SidebarPost category='me' title="Latest News" />}
      {/* popular videos */}
      {category !== 'popular-videos' && <SidebarPost newsType='videos' category='popular-videos' title="Popular Videos" />}
      {category === 'popular-videos' && <SidebarPost newsType='videos' category='videos' title="Latest Videos" />}
    </>
  )
}
