/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types';
import ReactPlayer from 'react-player'
import {
    FacebookShareButton,
    FacebookIcon,
    // TwitterShareButton,
    // TwitterIcon,
    WhatsappIcon,
    WhatsappShareButton
} from 'react-share';
import { formatDate } from '../../util';
// import WhatsappIcon from '../../assets/whatsapp.png'
// import FacebookIcon from '../../assets/facebook.png'
import TwitterIcon from '../../assets/x.png'
// import { getNewsApi } from '../../services/News.Service'
import useNews from '../../custom-hooks/news';
import { CircularProgress } from '@mui/material';
import PostSkeleton from './PostSkeleton';
import { openShareDialog } from '../../services/ShareService';
const { REACT_APP_URL } = process.env;
const twitterShareUrl = 'https://twitter.com/intent/tweet';

export default function Posts({ category, slug, newsDetail = false, token }) {
    // const [posts, setPosts] = useState([]);
    const navigate = useNavigate();
    const [currentCategory, setCurrentCategory] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [showRedirectionMessage, setShowRedirectionMessage] = useState(false);
    const [countDown, setCountDown] = useState(10);
    const [countDownInterval, setCountDownInterval] = useState(null);
    // const [rowPerPage] = useState(10);
    // const [totalRecords, setTotalRecords] = useState(1);
    // const [totalFetchedRecords, setTotalFetchedRecords] = useState(0);
    const { posts, getNewsData, totalRecords, totalFetchedRecords, isLoading } = useNews(10);
    // let countDownInterval = null;

    useEffect(() => {
        if (newsDetail && posts.length > 0) {
            if (posts[0]?.channel?.source !== 'bhai_digital') {
                setShowRedirectionMessage(true);
                setTimeout(() => {
                    navigateToLink(posts[0], '_self');
                    // setShowRedirectionMessage(false);
                }, 5 * 1000)
                const interval = setInterval(() => {
                    console.log('countDown', countDown);
                    if (countDown > 0) {
                        // let newCount = countDown - 1;
                        setCountDown((countDown) => countDown - 1);
                    }
                }, 1 * 1000)
                setCountDownInterval(interval);
            }
        }
    }, [posts]);

    useEffect(() => {
        console.log("countDown", countDown);
        console.log("countDownInterval", countDownInterval);
        if ((countDown <= 0 || showRedirectionMessage === false) && countDownInterval) {
            setCountDownInterval(null);
            clearInterval(countDownInterval);
        }
    }, [countDown, showRedirectionMessage])

    useEffect(() => {
        const page = (currentCategory !== category) ? 1 : currentPage;
        setCurrentCategory(category);
        getNewsData(page, category, slug, token);
    }, [currentPage, category, slug]);

    const parseDescription = (post) => {
        if (!newsDetail) {
            if (post?.description?.length > 300) {
                post.description = post.description.substring(0, 300);
            }
            // return `${post?.description} ${post.link ? '...' : ''}`
            return `${post?.description} ...`
        } else {
            return `${post?.description}`
        }
    }

    const getNext = () => {
        setCurrentPage(currentPage + 1)
    }

    // const playVideo = () => {

    // }
    const navigateToLink = (post, target = '_blank') => {
        if (post?.channel?.source === 'bhai_digital') {
            let linkPrefix = 'news-detail';
            if (post.newsType === 'video') {
                linkPrefix = 'video-detail';
            }
            const linkToNavigate = `/${linkPrefix}/${post.slug}`;
            navigate(linkToNavigate);
        } else {
            if (post?.link) {
                window.open(post?.link, target);
            }
        }
    }

    const getPostImage = (post) => {
        if (post.image) {
            if (post.slug) {
                return post.image.replace(/\.\w+$/, '.webp');
            }
            return post.image;
        }
        return null;
    }

    const navigateToTwitter = (url) => {
        openShareDialog(`${twitterShareUrl}?url=${url}`);
    }

    return (
        <div className='postContainer'>
            {posts.length === 0 && isLoading && <PostSkeleton></PostSkeleton>}
            {currentPage === 1 && posts.length > 0 && isLoading && <div className='center'><CircularProgress color='primary' /></div>}
            {posts.length > 0 && newsDetail && showRedirectionMessage && <div className='center'>You will be redirected to the actual source in {countDown} seconds. If you are not redirected, please  <a className='readMoreText' href={posts[0].link}>click here</a> to continue</div>}
            {posts.map((post, index) => {
                return (
                    <div key={index} className='post'>
                        <div className='topbarPost'>
                            <div className='postLogo'>
                                <img alt='' src={post.channel.image} />
                            </div>
                            <div className='socialMedia'>
                                {/* <img alt='' src={WhatsappIcon} /> */}
                                {post.slug && <div className="display-flex">
                                    <WhatsappShareButton
                                        url={`${REACT_APP_URL}/collaboration/${post.slug} `}
                                    // quote={'Dummy text!'}
                                    // hashtag="#muo"
                                    >
                                        <WhatsappIcon size={32} round />
                                    </WhatsappShareButton>&nbsp;
                                    <FacebookShareButton
                                        url={`${REACT_APP_URL}/collaboration/${post.slug} `}
                                    // quote={'Dummy text!'}
                                    // hashtag="#muo"
                                    >
                                        <FacebookIcon size={32} round />
                                    </FacebookShareButton>&nbsp;
                                    <button className='share-button twitter-button' onClick={() => navigateToTwitter(`${REACT_APP_URL}/collaboration/${post.slug} `)}>
                                        <img src={TwitterIcon} className='margin-left-0 twitter-image' />
                                        {/* <TwitterIcon size={32} round /> */}
                                    </button>
                                    {/* <TwitterShareButton
                                        url={`${ REACT_APP_URL } /collaboration/${ post.slug } `}
                                    // quote={'Dummy text!'}
                                    // hashtag="#muo"
                                    >
                                        <TwitterIcon size={32} round />
                                    </TwitterShareButton> */}
                                </div>}
                                <div>

                                </div>
                                {/* <img alt='' src={FacebookIcon} /> */}
                                {/* <img alt='' src={TwitterIcon} /> */}
                            </div>
                        </div>
                        <div className='postContent'>
                            <h2>{post.title}</h2>
                            {/* <span className='shareText'>3hr • 21 shares</span> */}
                            <div className='space-between-container'>
                                <div className='shareText'>{post?.categoryName}</div>
                                <div className='shareText'>{formatDate(post?.publicationDate)}</div>
                            </div>
                            {/* <span className='shareText'>{post?.categoryName}</span> */}
                            {post && post.image && !post.videoLink && <img
                                alt={post.title}
                                src={getPostImage(post)}
                                onError={(e) => e.target.src = post?.image}
                            />}
                            {post && post.newsType === 'video' && post.videoLink && <ReactPlayer url={post.videoLink} controls={true} width="auto" />}
                            <p dangerouslySetInnerHTML={{ __html: parseDescription(post) }} />
                            {(post.link || post?.channel?.source === 'bhai_digital') && !newsDetail && <button className="readMoreText" onClick={() => navigateToLink(post)}>Read More</button>}
                            {/* {post.link && !newsDetail && <a className="readMoreText" href={post.link} target="_blank" rel="noreferrer">Read More</a>} */}
                            {/* {post.slug && post.newsType === 'news' && !newsDetail && <button className="readMoreText" onClick={() => navigateToLink(`/ news - detail / ${ post.slug } `)} href={` / news - detail / ${ post.slug } `} target="_blank" rel="noreferrer">Read More</button>}
                            {post.slug && post.newsType === 'video' && !newsDetail && <button className="readMoreText" onClick={() => navigateToLink(`/ video - detail / ${ post.slug } `)} href={` / video - detail / ${ post.slug } `} target="_blank" rel="noreferrer">Read More</button>} */}
                        </div>
                    </div>
                );
            })}
            {posts.length === 0 && !isLoading && slug && <div className='no-news'>No News article found</div>}
            {posts.length === 0 && !isLoading && !slug &&
                <div className='no-news'>
                    Breaking Barriers, Breaking News: Experience the World's Stories, All in One Stream at
                    &nbsp;<Link to='/'>Bhai goes Digital</Link>
                </div>
            }
            {currentPage > 1 && isLoading && <div className='center'><CircularProgress color='primary' /></div>}
            {
                !isLoading && totalRecords !== totalFetchedRecords && totalRecords > 0 &&
                <div className='center'>
                    <Link className='viewMorebtn' onClick={getNext}>View More</Link>
                </div>
            }
        </div >
    )
}

Posts.propTypes = {
    category: PropTypes.string,
    slug: PropTypes.string,
    token: PropTypes.string,
    newsDetail: PropTypes.bool
};