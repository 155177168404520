import Home from '../pages/Home';
import Virals from '../pages/Virals';
import Videos from '../pages/Videos';
import NewsDetail from '../pages/NewsDetail';
import VideoDetail from '../pages/VideoDetail';

export const publicRouteObj = [
  {
    exact: true,
    path: '/',
    component: Home,
    key: 'home',
  },
  {
    exact: true,
    path: '/news/:category',
    component: Home,
    key: 'news',
  },
  {
    exact: true,
    path: '/news-detail/:slug',
    component: NewsDetail,
    key: 'news-detail',
  },
  {
    exact: true,
    path: '/video-detail/:slug',
    component: VideoDetail,
    key: 'video-detail',
  },
  {
    exact: true,
    path: '/virals',
    component: Virals,
    key: 'virals',
  },
  {
    exact: true,
    path: '/videos',
    component: Videos,
    key: 'videos',
  },
  {
    exact: true,
    path: '/videos/:category',
    component: Home,
    key: 'videos',
  },
];