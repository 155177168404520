import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from "react-router-dom";
import { getTagsApi } from "../services/Tag.Service";

export default function LeftCategory({ handleMenuToggle }) {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const defaultMenuItem = 'me';
  const [menuItems, setMenuItems] = useState([]);
  /*eslint no-unused-vars: 0*/
  const [menuItem, setMenuItem] = useState(defaultMenuItem);

  useEffect(() => {
    getMenuItems();
  }, []);

  const getMenuItems = async () => {
    const objTagQuery = {
      main_menu_item: 'true'
    }
    const response = await getTagsApi(objTagQuery);
    setMenuItems(response.data);
  }

  const getIcon = icon => {
    const arrIcons = {
      cup: "cupIcon",
      india: "indiaIcon",
      daily_share: "dailyIcon",
      science: "scienceIcon",
      entertainment: "entertinementIcon",
      business: "businessIcon",
      health: "healthIcon",
      gallery: "galleryIcon",
      fitness: "healthFitnessIcon",
    };
    if (arrIcons[icon]) {
      return arrIcons[icon];
    }
    return 'leftCommonIcon'
  };

  const navigateTo = (tag) => {
    if (handleMenuToggle) {
      handleMenuToggle(false);
    }
    setMenuItem(tag)
    navigate(`/news/${tag}`);
  }

  const isActive = (tag) => {
    if (pathname === `/news/${tag}`) {
      return true;
    }
    return false;
  }

  return (
    <Box className="leftPanel">
      <div className="leftTabs">
        <button onClick={() => navigateTo(defaultMenuItem)} className={`youIcon ${isActive(defaultMenuItem) || pathname === '/' ? 'active' : ''} `}>
          For You
        </button>
        {menuItems.map((m, index) =>
          <button
            onClick={() => navigateTo(m.tag)}
            key={index}
            disabled={!m.isMenuEnabled}
            className={`${getIcon(m.tag)} ${isActive(m.tag) ? 'active' : ''}`}
          >
            {m.displayName}
          </button>
        )}
      </div>
    </Box >
  );
}

LeftCategory.propTypes = {
  handleMenuToggle: PropTypes.func,
};
