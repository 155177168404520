import { useState } from 'react';
import { getNewsApi } from '../services/News.Service';
import { showToast } from '../util';

function useNews(rowPerPage) {

  const [posts, setPosts] = useState([]);
  const [totalRecords, setTotalRecords] = useState(1);
  const [totalFetchedRecords, setTotalFetchedRecords] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const getNewsData = async (page, category, slug, token) => {
    console.log("category", category)
    try {
      let requestTag = category === '' || category === 'me' || category === null ? null : category;
      if (category === 'popular') {
        requestTag = null;
      }
      const objGetNewsRequest = {
        page: page,
        page_size: rowPerPage,
        tag: requestTag
      }

      if (category === 'popular') {
        objGetNewsRequest.popular = true;
      }
      if (category === 'videos') {
        objGetNewsRequest.type = 'video';
        objGetNewsRequest.tag = '';
      }
      if (category === 'popular-videos') {
        objGetNewsRequest.type = 'video';
        objGetNewsRequest.popular = true;
        objGetNewsRequest.tag = '';
      }
      if (slug) {
        objGetNewsRequest.slug = slug;
      }
      console.log("objGetNewsRequest", objGetNewsRequest);
      setIsLoading(true);
      const response = await getNewsApi(objGetNewsRequest, token);
      setIsLoading(false);
      setTotalRecords(response.totalRecords);
      const arrRecords = (page === 1) ? response.data : [...posts, ...response.data];
      setTotalFetchedRecords(arrRecords.length);
      setPosts(arrRecords);
    } catch (e) {
      setIsLoading(false);
      const errorMessage = e?.response?.error?.msg ? e?.response?.error?.msg : 'Something went wrong. Please try again';
      showToast(errorMessage, 'error');
    }
  }

  return {
    posts,
    totalRecords,
    totalFetchedRecords,
    getNewsData,
    isLoading
  };
}

export default useNews;